import React from 'react';
import classNames from 'classnames';

import SVG from 'common/Icon/SVG';
import { CAROUSEL_ARROW_TYPE } from 'utils/constants';

const NextArrow = SVG['arrow-right'];
const PrevArrow = SVG['arrow-left'];

const CustomCarouselArrow = ({
  onClick,
  type,
  prevArrowLabel,
  nextArrowLabel,
}: PageContent.CustomCarouselArrowType) => (
  <button
    className={classNames('arrow', {
      [`arrow--${type}`]: type,
    })}
    onClick={onClick}
    type="button"
    aria-label={type === CAROUSEL_ARROW_TYPE.next ? nextArrowLabel : prevArrowLabel}
  >
    {type === CAROUSEL_ARROW_TYPE.next ? <NextArrow /> : <PrevArrow />}
  </button>
);

export default CustomCarouselArrow;
