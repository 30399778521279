import React from 'react';
import Slider from 'react-slick';

import { CAROUSEL_ARROW_TYPE, CAROUSEL_DEFAULT_SETTINGS } from 'utils/constants';

import CustomCarouselArrow from './CustomCarouselArrow';

import './CustomCarousel.scss';

const CustomCarousel = ({
  className = 'custom-carousel',
  settings = CAROUSEL_DEFAULT_SETTINGS,
  children,
  isWithCustomArrows = false,
  prevArrowLabel = 'previous arrow',
  nextArrowLabel = 'next arrow',
}: PageContent.CustomCarouselType) => (
  <Slider
    {...settings}
    {...{ className }}
    {...(isWithCustomArrows && {
      nextArrow: (
        <CustomCarouselArrow
          type={CAROUSEL_ARROW_TYPE.next}
          nextArrowLabel={nextArrowLabel}
          prevArrowLabel={prevArrowLabel}
        />
      ),
      prevArrow: (
        <CustomCarouselArrow
          type={CAROUSEL_ARROW_TYPE.prev}
          nextArrowLabel={nextArrowLabel}
          prevArrowLabel={prevArrowLabel}
        />
      ),
    })}
  >
    {children}
  </Slider>
);

export default CustomCarousel;
